import { Component, Input, OnInit } from '@angular/core';
import { ReferFriendComponent } from './refer-friend/refer-friend.component';
import { MatDialog } from '@angular/material/dialog';
import { MaterialModule } from 'src/app/material.module';
import { TablerIconsModule } from 'angular-tabler-icons';

@Component({
  selector: 'app-refer-friend-card',
  standalone: true,
  imports: [
    MaterialModule,
    TablerIconsModule
  ],
  templateUrl: './refer-friend-card.component.html',
  styleUrls: ['./refer-friend-card.component.scss'],
})
export class ReferFriendCardComponent  implements OnInit {

  @Input() profileData: any;

  constructor(
    private dialog: MatDialog,
  ) { }

  ngOnInit() {}

  referAFriendDialog() {
    let dialogData = {
      name: this.profileData?.full_name
    }

    const dialogRef = this.dialog.open(ReferFriendComponent, {
      data: dialogData,
      width: '500px'
      // disableClose: true
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result)
      // if (result?.event == 'add' || result?.event == 'edit') {
        
      // }
    });
  }

}
