<mat-card class="cardWithShadow cardInHeader">
  <mat-card-content class="p-y-24 text-center">
    <!-- <img
    src="/assets/images/mobile-qr.png"
    alt="img"
    width="150"
    /> -->
    
    
    <mat-card-title>Payroll Account Manager</mat-card-title>
    
    <p>
      <b>{{clientData?.manager_name}}</b>
      <br>
      <a href="mailto:{{clientData?.manager_email}}">{{clientData?.manager_email}}</a>
    </p>
    
    <p>
      <b>My Limited Company</b>
      <br>
      {{clientData?.company_name}}
    </p>

    <div class="downloadBtns">
      <button (click)="sendEmail()" mat-flat-button color="primary">
        <mat-icon matSuffix>
          <i-tabler name="mail" class="icon-20"></i-tabler>
        </mat-icon>
        Send Email
      </button>
    </div>
  </mat-card-content>
</mat-card>