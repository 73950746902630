import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Capacitor } from '@capacitor/core';
import { Share } from '@capacitor/share';
import { take } from 'rxjs';
import { MaterialModule } from 'src/app/material.module';
import { FirebaseAnalyticsService } from 'src/app/services/firebase-analytics.service';
import { ReferralSalesforce, ReferralService } from 'src/app/services/referal.service';
import { environment } from 'src/environments/environment';

export interface ShareObject {
  title: any,
  text?: any,
  url?: any,
  dialogTitle: any
}

@Component({
  selector: 'app-refer-friend',
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule
  ],
  templateUrl: './refer-friend.component.html',
  styleUrls: ['./refer-friend.component.scss'],
})
export class ReferFriendComponent  implements OnInit {

  messageStart: string = "Hi";

  messageEnd: string = `<p>Firstly, thank you for being confident enough in our service that you would recommend it to a friend or colleague.</p>
  
  <p>As a way of saying thank you, we will add a credit of <b>€25</b> for every contractor that schedules a call with us, even if they don't sign up! It gets better, if they do sign up, we'll give you an extra <b>€75</b> gift card per client as soon as they have paid their first management fee.</p>
  
  <p>So, what next? Click on the next button below where we have prepared an email that will populate for you that you can send to your friends or colleagues, as soon as they click on the link and schedule a call - we will be notified, and we will add the €25 credit to your account.</p>
  
  <p>If they proceed to sign up, we will notify your account manager and ask them to send a €75 voucher your way!</p>
  
  <p>Thank you for your continued support and for trusting in our services enough to recommend them.</p>
  
  <p>The Icon Accounting Team</p>`;

  finalMessage: string = '';

  shareText: string = '';

  step: 1 | 2 = 1;

  referFriendForm: FormGroup;

  loading: boolean = false;

  isNativePlatform: boolean = Capacitor.isNativePlatform();

  constructor(
    public dialogRef: MatDialogRef<ReferFriendComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: {name: string},
    private _snackBar: MatSnackBar,
    private readonly fb: FormBuilder,
    private referralService: ReferralService,
    private firebaseAnalytics: FirebaseAnalyticsService
  ) {
    this.referFriendForm = this.fb.group({
      firstName: [null, Validators.required],
      lastName: [null, Validators.required],
      email: [null, [Validators.required, Validators.email]],
    });
  }

  get form() {
    return this.referFriendForm.controls;
  }

  ngOnInit() {
    this.finalMessage = this.messageStart + " " + this.data.name + " " + this.messageEnd;

    this.shareText = `Hello,\n\nAs you are more than likely aware, I am an independent professional working on a project-basis. My accountancy partner, Icon Accounting has recently introduced a referral programme to celebrate the launch of their latest feature: iPerks.\niPerks is an added value feature exclusive to Icon Accounting that gives every client access to bespoke wellness programmes, everyday lifestyle savings and over 4000 online courses\n\nIf you would like to hear more about the service offered by Icon Accounting, please click on the below link to organise a call at a time that suits you!\nIf you do set up a call, I will receive a gesture from Icon Accounting as a thank you for introducing their services to you, and you will also receive a special sign-up offer.\n\nWhether you operate through an umbrella company or a private limited company, the team at Icon Accounting have over 13 years' experience have helped over 10,000 contractors.\n\nI would recommend Icon Accounting based on the service I have experienced since joining their service.\n\nYes, I would like to hear more about Icon Accounting: ${environment.portalUrl}/client/referral-form \nI'm not convinced yet - but I do have 60 seconds to watch a video that will change my mind https://youtu.be/jPO6yVOOR5Y \n\nKind Regards, ${this.data.name}`
  }

  cancel(event?: string) {
    this.dialogRef.close({ event: event || 'cancel'});
  }

  sendReferral() {
    this.loading = true;

    const dataToSend: ReferralSalesforce = {
      referral_first_name: this.form['firstName'].value!,
      referral_last_name: this.form['lastName'].value!,
      referral_email: this.form['email'].value!,
    };

    console.log(dataToSend)

    const email = dataToSend.referral_email;
    const subject = "Let's talk about Contracting";
    const body = this.shareText;
    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

    if (this.isNativePlatform) {
      this.share(subject, body);
    } else {
      // Open the default email client
      window.location.href = mailtoLink;
    }

    this.referralService.sendReferral(dataToSend).pipe(take(1)).subscribe({
      next: res => {
        this.loading = false;
        this.firebaseAnalytics.logEvent('referral_sent', { description: 'referral sent for ' + dataToSend.referral_email + ' from ' + this.data.name});
        this._snackBar.open('Thank you for your referral!', 'Close', { horizontalPosition: 'center', verticalPosition: 'top',  duration: 3000, panelClass: 'customSnackBar' });
        this.cancel('add');
      },
      error: err => {
        this.loading = false;
        this._snackBar.open(err.error.message, 'Close', { horizontalPosition: 'center', verticalPosition: 'top',  duration: 3000, panelClass: 'customSnackBar' });
      }
    });
  }

  async share(title: string, text: string) {
    let shareObj: ShareObject = {
      title: title,
      dialogTitle: "Refer a friend",
      text: text
    };
    console.log(shareObj)
    await Share.share(shareObj);
  }

}
