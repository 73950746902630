<mat-card class="cardWithShadow cardInHeader">
  <mat-card-content [class.p-y-24]="options.theme === 'dark'" [class.p-b-24]="options.theme === 'light'" [class.p-t-0]="options.theme === 'light'" class="text-center">
    @if(options.theme === 'light') {
      <img (click)="iPERKSLogin()" src="assets/images/iPERKS_logo-light.svg" style="width: 200px; margin-bottom: -25px;"/>
    }
    @if(options.theme === 'dark') {
      <img (click)="iPERKSLogin()" src="assets/images/iPERKS_logo.svg"/>
    }
    <button mat-flat-button color="primary" class="w-100 m-t-10" (click)="iPERKSLogin()">Login</button>
  </mat-card-content>
</mat-card>