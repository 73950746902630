import { Component, Input, OnInit } from '@angular/core';
import { TablerIconsModule } from 'angular-tabler-icons';
import { MaterialModule } from 'src/app/material.module';
import { ClientData } from '../../invoices/invoices-models';
import { FirebaseAnalyticsService } from 'src/app/services/firebase-analytics.service';

@Component({
  selector: 'app-account-manager-info',
  standalone: true,
  imports: [
    MaterialModule,
    TablerIconsModule
  ],
  templateUrl: './account-manager-info.component.html',
  styleUrls: ['./account-manager-info.component.scss'],
})
export class AccountManagerInfoComponent  implements OnInit {

  @Input() clientData: ClientData;

  constructor(
    private firebaseAnalytics: FirebaseAnalyticsService
  ) { }

  ngOnInit() {}
  
  sendEmail() {
    this.firebaseAnalytics.logEvent('send_mail_click', { description: 'clicked on send mail button'});
    window.location.href = 'mailto:'+this.clientData.manager_email;
  }
}
