import { Component, OnInit } from '@angular/core';
import { MaterialModule } from 'src/app/material.module';
import { CoreService } from 'src/app/services/core.service';
import { FirebaseAnalyticsService } from 'src/app/services/firebase-analytics.service';

@Component({
  selector: 'app-iPerks',
  standalone: true,
  imports: [
    MaterialModule
  ],
  templateUrl: './i-perks.component.html',
  styleUrls: ['./i-perks.component.scss'],
})
export class IPerksComponent  implements OnInit {

  options = this.settings.getOptions();

  constructor(
    private settings: CoreService,
    private firebaseAnalytics: FirebaseAnalyticsService
  ) { }

  ngOnInit() {}

  iPERKSLogin() {
    this.firebaseAnalytics.logEvent('iPERKS_login_click', { description: 'clicked on iPERKS login button'});
    const iPerksUrl = 'https://iperks.group-login.com/login';
    window.open(iPerksUrl, '_blank');
  }

}
