<h2 mat-dialog-title class="m-b-20">Send Feedback</h2>
<mat-divider></mat-divider>

<mat-dialog-content class="mat-typography" style="max-width: 600px">

  <p>
    {{text}}
  </p>

  <!-- <mat-form-field appearance="outline" class="w-100">
    <mat-label>Message</mat-label>
    <textarea
      [(ngModel)]="message"
      rows="5"
      matInput
      placeholder="Message"
    ></textarea>
  </mat-form-field> -->

  <form [formGroup]="feedbackForm">

    <mat-form-field appearance="outline" class="w-100">
      <mat-label>Feedback Type</mat-label>
      <mat-select #feedbackType formControlName="feedback_type">
        <mat-option value="new_feature">New Feature or Improvement Suggestion</mat-option>
        <mat-option value="send_feedback">General Feedback</mat-option>
        <mat-option value="report_a_bug">Report a Bug</mat-option>
      </mat-select>
    </mat-form-field>

    @if (feedbackType.value) {
      <div>
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>{{feedbackType.value != 'report_a_bug' ? 'Message' : 'Describe what went wrong'}}</mat-label>
              <textarea
                formControlName="message"
                rows="5"
                matInput
                [placeholder]="feedbackType.value === 'new_feature' ? 'Describe your suggestion and how this will benefit you (optional add attachment)' : feedbackType.value === 'send_feedback' ? 'Write down Your feedback (optional add attachment)' : 'Describe what went wrong'">
              </textarea>
            </mat-form-field>
          </div>
      }

      @if(feedbackType.value === 'report_a_bug') {
        <div class="hstack">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Date of the issue</mat-label>
            <input matInput [matDatepicker]="picker" formControlName="date_time">
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </mat-form-field>
  
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Time when the issue occurred</mat-label>
            <input matInput name="end" formControlName="time" type="time">
          </mat-form-field>
        </div>

        
  
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Device Type</mat-label>
          <mat-select formControlName="device_type">
            <mat-option value="Web App">Web App</mat-option>
            <mat-option value="Mobile App (iOS)">Mobile App (iOS)</mat-option>
            <mat-option value="Mobile App (Android)">Mobile App (Android)</mat-option>
          </mat-select>
        </mat-form-field>
  
        <mat-form-field appearance="outline" class="w-100">
          <mat-label>Steps to reproduce the issue</mat-label>
          <textarea
            formControlName="steps_to_reproduce"
            rows="5"
            matInput
            placeholder="Steps to reproduce the issue">
          </textarea>
        </mat-form-field>
      }

      @if(feedbackType.value) {
        <p>Attach a screenshot or screenshots</p>
        <app-upload [multiple]="true" [allowedExtensions]="allowedExtensions" [maxFileSize]="maxFileSize" (filesUploaded)="fileAdded($event)" (fileDeleted)="fileDeleted($event)"></app-upload>
        <p>Supported file types: {{allowedExtensions}}</p>
        <p>Max file size: {{maxFileSize}}Mb</p>
      }
  </form>

</mat-dialog-content>

<mat-divider></mat-divider>
<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>
<mat-dialog-actions class="p-24">
  <button mat-stroked-button color="warn" (click)="cancel()">Cancel</button>
  <button
    color="accent"
    mat-flat-button
    [disabled]="loading || !feedbackType.value"
    (click)="sendFeedback(message)"
  >
  Send
  </button>
</mat-dialog-actions>
