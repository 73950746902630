<mat-card class="cardWithShadow cardInHeader">
  <mat-card-content class="p-y-24 text-center">
    <mat-icon matSuffix>
      <i-tabler name="users-group" class="icon-24"></i-tabler>
    </mat-icon>
    <h5 class="f-w-600 f-s-18 m-b-15 m-t-0">Referral</h5>
    <button mat-flat-button color="primary" class="w-100" (click)="referAFriendDialog()">
      
      Refer a Friend
    </button>
  </mat-card-content>
</mat-card>