import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { take } from 'rxjs';
import { UploadComponent } from 'src/app/componentsCustom/upload/upload.component';
import { MaterialModule } from 'src/app/material.module';
import { DashboardPageService } from 'src/app/services/dashboard-page.service';

@Component({
  selector: 'app-send-feedback',
  standalone: true,
  imports: [
    CommonModule,
    MaterialModule,
    FormsModule,
    ReactiveFormsModule,
    UploadComponent
  ],
  templateUrl: './send-feedback.component.html',
  styleUrls: ['./send-feedback.component.scss'],
})
export class SendFeedbackComponent  implements OnInit {

  text: string = "Help us improve by suggesting new features, reporting bugs, or leaving feedback. Your input shapes the future of our platform!";
  message: string = '';

  allowedExtensions: string = '.jpg,.jpeg,.png';
  maxFileSize: number = 4;

  loading: boolean = false;

  feedbackForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<SendFeedbackComponent>,
    private dashboardService: DashboardPageService,
    private _snackBar: MatSnackBar,
    private fb: FormBuilder,
    private router: Router
  ) {
    this.feedbackForm = this.fb.group({
      feedback_type: [''],
      message: [''],
      date_time: [''],
      time: [null],
      device_type: [''],
      steps_to_reproduce: [''],
      attachments: [[]],
      url: ['']
    });
  }

  ngOnInit() {}

  cancel(event?: string) {
    this.dialogRef.close({ event: event || 'cancel'});
  }

  sendFeedback(message: string) {
    this.loading = true;

    console.log(message)
    const dataToSend = {
      feedback_type: this.feedbackForm.get('feedback_type')!.value,
      message: this.feedbackForm.get('message')!.value,
      date_time: this.combineDateAndTime(this.feedbackForm.get('date_time')!.value, this.feedbackForm.get('time')!.value),
      device_type: this.feedbackForm.get('device_type')!.value,
      steps_to_reproduce: this.feedbackForm.get('steps_to_reproduce')!.value,
      attachments: this.feedbackForm.get('attachments')!.value,
      url: this.router.url
    };

    this.dashboardService.sendFeedback(dataToSend).pipe(take(1)).subscribe({
      next: res => {
        this.loading = false;
        const msg = 'Thank you for your feedback! Our team will review it shortly.';
        this._snackBar.open(msg, 'Close', { horizontalPosition: 'center', verticalPosition: 'top',  duration: 3000, panelClass: 'customSnackBar' });
        this.cancel('add');
      },
      error: err => {
        this.loading = false;
        const msg = err.error.error;
        this._snackBar.open(msg, 'Close', { horizontalPosition: 'center', verticalPosition: 'top',  duration: 3000, panelClass: 'customSnackBar' });
      }
    });
  }

  combineDateAndTime(date: string, time: string): string {
    if (!date && !time) return '';

    if (date && !time) {
      const dateObj = new Date(date);
      return dateObj.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
    }
    if (!date && time) {
      return time;
    }

    const [hours, minutes] = time.split(':');
    const dateTime = new Date(date);
    dateTime.setHours(Number(hours), Number(minutes), 0, 0);
    const options: Intl.DateTimeFormatOptions = {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit'
    };
    return dateTime.toLocaleString('en-US', options);
  }
  

  fileAdded(files: any) {
    const attachments = files.map((file: File) => {
      return new Promise<{ filename: string, content: string }>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => {
          resolve({ filename: file.name, content: (reader.result as string).split(',')[1] });
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      });
    });

    Promise.all(attachments).then((attachments) => {
      this.feedbackForm.patchValue({ attachments });
      console.log(this.feedbackForm.value.attachments);
    }).catch(error => {
      console.error('Error reading file attachments:', error);
    });
  }

  fileDeleted(event: string) {
    console.log(event)
    
  }

}
