import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { SendFeedbackComponent } from './send-feedback/send-feedback.component';
import { MaterialModule } from 'src/app/material.module';
import { TablerIconsModule } from 'angular-tabler-icons';

@Component({
  selector: 'app-send-feedback-card',
  standalone: true,
  imports: [
    MaterialModule,
    TablerIconsModule
  ],
  templateUrl: './send-feedback-card.component.html',
  styleUrls: ['./send-feedback-card.component.scss'],
})
export class SendFeedbackCardComponent  implements OnInit {

  constructor(
    private dialog: MatDialog,
  ) { }

  ngOnInit() {}

  sendFeedbackDialog() {
    let dialogData = {
      
    }

    const dialogRef = this.dialog.open(SendFeedbackComponent, {
      data: dialogData,
      width: '500px'
      // disableClose: true
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log(result)
      // if (result?.event == 'add' || result?.event == 'edit') {
        
      // }
    });
  }

}
