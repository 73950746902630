<h2 mat-dialog-title class="m-b-20">Refer a friend</h2>
<mat-divider></mat-divider>

<mat-dialog-content class="mat-typography" style="max-width: 600px">

  @if(step == 1) {
    <div [innerHtml]="finalMessage"></div>
  }

  @if(step == 2) {
    <form [formGroup]="referFriendForm">
      <mat-form-field appearance="outline" class="w-100" color="primary">
        <mat-label>First Name</mat-label>
        <input matInput formControlName="firstName" placeholder="First Name" />
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-100" color="primary">
        <mat-label>Last Name</mat-label>
        <input matInput formControlName="lastName" placeholder="Last Name" />
      </mat-form-field>

      <mat-form-field appearance="outline" class="w-100" color="primary">
        <mat-label>Email</mat-label>
        <input matInput formControlName="email" placeholder="Email" />
      </mat-form-field>
    </form>
  }

</mat-dialog-content>
<mat-progress-bar *ngIf="loading" mode="indeterminate"></mat-progress-bar>

<mat-divider></mat-divider>

<mat-dialog-actions class="p-24">
  <button mat-stroked-button color="warn" (click)="cancel()">Cancel</button>
  @if(step == 1) {
    <button
    color="accent"
    mat-flat-button
    (click)="step = 2">
    Next
  </button>
  }
  @if(step == 2) {
    <button
    color="accent"
    mat-flat-button
    (click)="sendReferral()"
    [disabled]="referFriendForm.invalid">
    Send Referral
  </button>
  }
</mat-dialog-actions>
